@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(226 232 240);
}

.active-body {
  overflow-y: hidden;
}

.MuiDataGrid-filterForm {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

@media screen and (max-width: 515px) {
  .MuiDataGrid-panelWrapper {
    display: block;
    width: 80%;
    left: 5%;
  }

  .MuiDataGrid-panelWrapper .MuiFormControl-root:last-child {
    margin-left: 18px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .nav-bar {
    width: calc(100% - 20px);
  }

  .deleting-modal {
    width: 94%;
  }
}
